import React from 'react';
import { AboutMeCard, Project, SkillLogos } from './interfaces';
import { GiBrain, GiArtificialIntelligence, GiBookPile } from 'react-icons/gi';

import logoJS from './assets/logos/logo-javascript.png';
import logoTS from './assets/logos/logo-typescript.png';
import logoHtml from './assets/logos/logo-html.png';
import logoCss from './assets/logos/logo-css.png';
import logoCSharp from './assets/logos/logo-c-sharp.png';
import logoCPlusPlus from './assets/logos/logo-c++.png';
import logoReact from './assets/logos/logo-react.jpg';
import logoGit from './assets/logos/logo-git.png';

import logoVscode from './assets/logos/logo-vscode.png';
import logoVS from './assets/logos/logo-visual-studio.png';
import logoSQLServer from './assets/logos/logo-sql-server.png';
import logoUnrealEngine5 from './assets/logos/logo-unreal-engine-5.png';
import logoNetlify from './assets/logos/logo-netlify.png';

import logoSQL from './assets/logos/logo-sql.png';
import logoMySQL from './assets/logos/logo-mysql.png';
import logoDevExpressFramework from './assets/logos/logo-devexpress-framework.png';
import logoPython from './assets/logos/logo-python.png';
import logoQtFramework from './assets/logos/logo-qt-framework.png';
import logoXAML from './assets/logos/logo-xaml.png';
import logoHexEditor from './assets/logos/logo-hex-editor.png';
import logoGimp from './assets/logos/logo-gimp.png';

import gsf1 from './assets/projects/gsf1.png';
import gsf2 from './assets/projects/gsf2.png';
import gsf3 from './assets/projects/gsf3.png';
import gsf4 from './assets/projects/gsf4.png';
import gsf5 from './assets/projects/gsf5.png';
import gsf6 from './assets/projects/gsf6.png';
import gsf7 from './assets/projects/gsf7.png';
import gsf8 from './assets/projects/gsf8.png';
import gsf9 from './assets/projects/gsf9.png';
import gsf10 from './assets/projects/gsf10.png';
import gsf11 from './assets/projects/gsf11.png';
import gsf12 from './assets/projects/gsf12.png';

import gsfc1 from './assets/projects/gsfc1.png';
import gsfc2 from './assets/projects/gsfc2.png';
import gsfc3 from './assets/projects/gsfc3.png';
import gsfc4 from './assets/projects/gsfc4.png';
import gsfc5 from './assets/projects/gsfc5.png';
import gsfc6 from './assets/projects/gsfc6.png';
import gsfc7 from './assets/projects/gsfc7.png';
import gsfc8 from './assets/projects/gsfc8.png';
import gsfc9 from './assets/projects/gsfc9.png';

import sadr1 from './assets/projects/sadr1.png';

import ed1 from './assets/projects/ed1.png';
import ed2 from './assets/projects/ed2.png';


import fv1 from './assets/projects/fv1.png';
import fv2 from './assets/projects/fv2.png';

import enigmatica1 from './assets/projects/EnigmaticaFileEncrypter.jpg';

import fs1 from './assets/projects/fs1.png';
import fs2 from './assets/projects/fs2.png';
import fs3 from './assets/projects/fs3.png';
import fs4 from './assets/projects/fs4.png';

import scrc1 from './assets/projects/scrc1.png';
import scrc2 from './assets/projects/scrc2.png';
import scrc3 from './assets/projects/scrc3.png';
import scrc4 from './assets/projects/scrc4.png';

import GajimOmemo1 from './assets/projects/GajimOmemo.png';

export const AboutMeCards: AboutMeCard[] = [
  {
    icon: <GiBookPile />,
    title: 'POLYVALENCE',
    description:
      "En parallèle du travail dans les Ressources Humaines, je suis engagé dans le développement d'applications de bureau et de sites Web pour l'Établissement Public de Télévision à l'aide de technologies de développement multiplateforme modernes.",
  },
  {
    icon: <GiBrain />,
    title: 'EFFICACITÉ',
    description:
      "J'adore travailler de manière intelligente, en employant des stratégies innovantes et des solutions efficaces et optimisées pour chaque défi.",
  },
  {
    icon: <GiArtificialIntelligence  />,
    title: 'MAVERICK',
    description:
      "En dehors de mon travail et de mes obligations familliales habituelles, je passe la quasi-totalité de mon temps sur mon PC, Maverick de nature, ̶r̶e̶d̶a̶c̶t̶e̶d̶, youtuber, et aussi gamer et moddeur hardcore, je suis toujours trés curieux et toujours branché coté téchnologie, hardware et en géopolitique des conflits.",
  },
];

export const Logos: SkillLogos = {
  firstRow: [
    { img: logoJS, name: 'JavaScript' },
    { img: logoTS, name: 'TypeScript' },
    { img: logoHtml, name: 'HTML' },
    { img: logoCss, name: 'CSS' },
    { img: logoCSharp, name: 'C#' },
    { img: logoCPlusPlus, name: 'C++' },
    { img: logoReact, name: 'React' },
    { img: logoUnrealEngine5, name: 'Unreal Engine 5' },
  ],
  secondRow: [
    { img: logoVscode, name: 'VS Code' },
    { img: logoVS, name: 'Visual Studio' },
    { img: logoSQL, name: 'SQL' },
    { img: logoSQLServer, name: 'SQL Server' },
    { img: logoMySQL, name: 'MySQL' },
    { img: logoPython, name: 'Python' },
  ],
  thirdRow: [
    { img: logoGit, name: 'Git' },
    { img: logoNetlify, name: 'Netlify' },
    { img: logoGimp, name: 'Gimp' },
    { img: logoDevExpressFramework, name: 'DevExpress Framework' },
    { img: logoQtFramework, name: 'Qt Framework' },
    { img: logoXAML, name: 'XAML' },
    { img: logoHexEditor, name: 'Hex Editor' },
  ],
};

export const ProjectList: Project[] = [
  {
    thumbnail: gsf1,
    logo: gsf1,
    title: 'Gestion et Suivi de la Formation',
    description:
      "Une application de bureau pour la gestion et le suivi complet de la formation des apprentis et des stagiaires.",
    tools: [
      'C#',
      'DevExpress Framework',
      '.Net',
    ],
    // MAX 10? long images
    gallery: [
      { src: gsf1, isVideo: false },
      { src: gsf2, isVideo: false },
      { src: gsf3, isVideo: false },
      { src: gsf4, isVideo: false },
      { src: gsf5, isVideo: false },
      { src: gsf6, isVideo: false },
      { src: gsf7, isVideo: false },
      { src: gsf8, isVideo: false },
      { src: gsf9, isVideo: false },
      { src: gsf10, isVideo: false },
      { src: gsf11, isVideo: false },
      { src: gsf12, isVideo: false },
    ],
  },
  {
    thumbnail: gsfc1,
    logo: gsfc1,
    title: "Gestion et Suivi de la Formation Continue",
    description:
      'Une application de bureau pour la gestion et le suivi complet de la formation continue.',
    tools: [
      'C#',
      'DevExpress Framework',
      'WPF',
      'XAML',
      '.Net',
    ],
    gallery: [
      { src: gsfc1, isVideo: false },
      { src: gsfc2, isVideo: false },
      { src: gsfc3, isVideo: false },
      { src: gsfc4, isVideo: false },
      { src: gsfc5, isVideo: false },
      { src: gsfc6, isVideo: false },
      { src: gsfc7, isVideo: false },
      { src: gsfc8, isVideo: false },
      { src: gsfc9, isVideo: false },
    ],
  },
  {
    thumbnail: fs1,
    logo: fs1,
    title: "Formations & Stages – EPTV",
    description:
      "Un site web pour l'inscription des stagiaires journalistes afin de faire leur stage pratique au sein de l'EPTV.",
    tools: [
      'JavaScript',
      'CSS',
      'HTML',
      'WordPress',
      'Web',
    ],
    gallery: [
      { src: fs1, isVideo: false },
      { src: fs2, isVideo: false },
      { src: fs3, isVideo: false },
      { src: fs4, isVideo: false },
    ],
  },
  {
    thumbnail: ed1,
    logo: ed1,
    title: "Exportation Données ",
    description:
      "Double application de bureau pour l'exportation des données externes du site web et autre vers la base de donnée principale située sur le serveur.",
    tools: ['C#', 'WPF', 'XAML', '.Net'],
    gallery: [
      { src: ed1, isVideo: false },
      { src: ed2, isVideo: false },
    ],
  },
  {
    thumbnail: sadr1,
    logo: sadr1,
    title: "Suivi des apprentis pour le Département de la Rémunération",
    description:
      "Une application de bureau pour le suivi des apprentis notamment le changement du taux de rémunération pour le mois en cours.",
    tools: ['C#', 'WPF', 'XAML', '.Net'],
    gallery: [
      { src: sadr1, isVideo: false },
    ],
  },
  {
    thumbnail: fv1,
    logo: fv1,
    title: "Fiches de Voeux",
    description:
      "Une application de bureau pour le recueil des besoins de formation individuelle de chaque employé selon sa spécialité et affectation/direction.",
    tools: ['C#', 'WPF', 'XAML', '.Net'],
    gallery: [
      { src: fv1, isVideo: false },
      { src: fv2, isVideo: false },
    ],
  },
  {
    thumbnail: enigmatica1,
    logo: enigmatica1,
    title: 'Enigmatica',
    description:
      'Une application de bureau pour la cryptographie de fichiers utilisant un algorithme custom.',
    tools: ['C#', 'WPF', 'XAML', '.Net'],
    gallery: [
      { src: enigmatica1, isVideo: false },
    ],
  },
  {
    thumbnail: GajimOmemo1,
    logo: GajimOmemo1,
    title: 'Plugin custom de Chiffrement OMEMO',
    description:
      'Modification du plugin basé sur le chiffrement Helffie-Delfman de bout en bout (OMEMO).',
    tools: ['Python', 'Gajim', 'Omemo'],
    gallery: [
      { src: GajimOmemo1, isVideo: false },
    ],
  },
  {
    thumbnail: scrc1,
    logo: scrc1,
    title: 'Star Citizen Referral Code',
    description:
      'Un site web pour la publicité organique priviligéant la rapidité et le SEO.',
    tools: ['JavaScript', 'HTML', 'Web', 'SASS'],
    gallery: [
      { src: scrc1, isVideo: false },
      { src: scrc2, isVideo: false },
      { src: scrc3, isVideo: false },
      { src: scrc4, isVideo: false },
    ],
  },
];
