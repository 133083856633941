import React, { useEffect, useState } from 'react';
import '../sass/pages/about-me.scss';
import { useNav } from '../hooks/useNav';
import { AboutMeCards } from '../content';

const AboutMe = () => {
  const aboutMeRef = useNav('apropos');

  // Returns the 3 cards in About Me page
  const Cards = () => {
    return (
      <>
        {AboutMeCards.map((card, index) => (
          <div className="card" key={index}>
            <div className="card-title">
              <div className="icon">{card.icon}</div>
              <h2>{card.title}</h2>
            </div>
            <div className="card-description">
              <p>{card.description}</p>
            </div>
          </div>
        ))}
      </>
    );
  };

  const ContinueText = () => {
    const [isBlinking, setIsBlinking] = useState(true);

    useEffect(() => {
      // Cursor blinking effect
      const interval = setInterval(() => {
        setIsBlinking((prev) => !prev);
      }, 500);

      return () => clearInterval(interval);
    }, []);

    return (
      <p className="txtFaded">
        <span className={`blinking ${isBlinking ? 'visible' : 'hidden'}`}>
          &gt;
        </span>{' '}
        continuer (<span className="txtHighlightCyan">O</span>/
        <span className="txtHighlightCyan">N</span>)
        <span className="txtHighlightCyan">?</span>
      </p>
    );
  };

  return (
    <section id="apropos" ref={aboutMeRef}>
      <header>
        <h1>A propos</h1>
      </header>
      <div className="about-me-container">
        {/* Introduction */}
        <div className="info">
          <p className="name">Salutations! je me présente; DJERIDI Mohamed Chawki</p>
          <br />
          <p>
          Un fervent passionné d'informatique depuis mon enfance dédié à la maîtrise des technologies de pointe et à façonner l'avenir en tant que résolveur de problèmes compétent et développeur de l'ère numérique.
            <br />
            <br />
            J'élargis continuellement mes compétences et mes connaissances, facilité par ma maitrise de l'anglais et du francais et désireux de contribuer à des projets innovants. Je suis engagé dans mon parcours en développement logiciel et enthousiasmé par les opportunités à venir.
          </p>
        </div>
        {/* About me cards */}
        <div className="cards-group">
          <Cards />
        </div>
        {/* Background text */}
        <div className="bg-text">
          <ContinueText />
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
