import React, { useState, useRef } from 'react';
import '../sass/pages/contact-me.scss';
import emailjs from '@emailjs/browser';
import { useNav } from '../hooks/useNav';
import { ContactFormError, ContactFormValues } from '../interfaces';

const ContactMe = () => {
  const contactMeRef = useNav('contact');
  const [formValues, setFormValues] = useState<ContactFormValues>({
    user_name: '',
    user_email: '',
    message: '',
  });
  const [formErrors, setFormErrors] = useState<ContactFormError>({
    user_name: false,
    user_email: false,
    message: false,
  });
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState(false);
  const [emailIsSending, setIsEmailSending] = useState(false);
  const form = useRef<any>();

  const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID ?? 'service_omsn2rh';
  const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID ?? 'template_48mblt2';
  const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY ?? 'gjeLxb0tBwFJW0WGB';

  const sendEmail = (e: any) => {
    e.preventDefault();

    setEmailSent(false);
    setError(false);
    setIsEmailSending(false);

    const isNameInvalid = formValues.user_name === '';
    const isEmailInvalid =
      formValues.user_email === '' || !isValidEmail(formValues.user_email);
    const isMessageInvalid = formValues.message === '';

    // Validate form
    if (isNameInvalid || isEmailInvalid || isMessageInvalid) {
      setFormErrors({
        user_name: isNameInvalid,
        user_email: isEmailInvalid,
        message: isMessageInvalid,
      });
      return;
    }

    setIsEmailSending(true);

    // Send email
    emailjs
      .sendForm(serviceID, templateID, form.current, {
        publicKey: publicKey,
      })
      .then(
        () => {
          console.log('Email envoyé avec succés');
          // Clear form and show confirmation message
          (form.current as HTMLFormElement).reset();
          setEmailSent(true);
          setFormErrors({
            user_name: false,
            user_email: false,
            message: false,
          });
          setIsEmailSending(false);
        },
        (error) => {
          console.log('Erreur dans l"envoi d"email: ', error);
          setError(true);
          setFormErrors({
            user_name: false,
            user_email: false,
            message: false,
          });
          setIsEmailSending(false);
        }
      );
  };

  // Email validationregex
  const isValidEmail = (email: string) => {
    return email.match(/^\S+@\S+\.\S+$/);
  };

  const handleInputChange = (e: any) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value.trim(),
    });
  };


  return (
    <section id="contact" ref={contactMeRef}>
      <header className="contact-header">
        <h1>Contactez moi</h1>
      </header>
      <div className="contact-container">
        <div className="form-container">
          <p>
            Vous souhaitez me contacter et voir mon CV?
            <br />
            <br />
            N'hésitez pas à me contacter via le formulaire ci-dessous{' '}
          </p>
          {/* Contact me form */}
          <form ref={form} onSubmit={sendEmail}>
            <input
              className={`${formErrors.user_name ? 'error' : ''}`}
              type="text"
              name="user_name"
              placeholder="Nom Complet"
              onChange={handleInputChange}
            />
            <input
              className={`${formErrors.user_email ? 'error' : ''}`}
              type="email"
              name="user_email"
              placeholder="Email"
              onChange={handleInputChange}
            />
            <textarea
              className={`${formErrors.message ? 'error' : ''}`}
              name="message"
              placeholder="Votre message"
              onChange={handleInputChange}
            />
            <input
              type="submit"
              value={`${emailIsSending ? 'ENVOI . . .' : 'ENVOYER MESSAGE'}`}
              disabled={emailIsSending}
            />

            {/* Success message */}
            <div className={`success-text ${emailSent ? 'visible' : ''}`}>
              <p>
                Votre message à été envoyé avec succès, merci!
                <br />Je vous répondrai dès que possible.
              </p>
            </div>

            {/* Error message */}
            <div className={`error-text ${error ? 'visible' : ''}`}>
              <p>
                {/* Hmm quelque chose c'est mal passé avec le formulaire. qu'on est t-il
                de m'envoyer un message sur{' '}
                <a
                  href="https://www.linkedin.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  LinkedIn
                </a>{' '}
                plutot? Désolé pour ca! */}
                Hmm.. ça n'a pas fonctionné, désolé pour ça ! 
                Vous pouvez m'envoyer un message directement à {' '}
                <span
                  onClick={() =>
                    window.open('mailto:workfetcheptv@gmail.com', '_blank')
                  }
                >
                  workfetcheptv@gmail.com
                </span>{' '}
                en l'occurence.
              </p>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactMe;
