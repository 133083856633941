import React from 'react';
import '../sass/pages/skills.scss';
import { useNav } from '../hooks/useNav';
import { SkillLogos } from '../interfaces';
import { Logos } from '../content';

const Skills = () => {
  const skillsRef = useNav('compétences');

  // Skills table
  const Skills = () => {
    return (
      <div className="skills-table">
        {Object.keys(Logos).map((key, index) => (
          <div
            className={`table-row ${
              index % 2 === 0 ? 'right-to-left' : 'left-to-right'
            }`}
            key={key}
          >
            {Logos[key as keyof SkillLogos]
              .concat(Logos[key as keyof SkillLogos])
              .map((item, index) => (
                <div key={index} className="row-item">
                  <img src={item.img} alt={item.name} />
                  <p>{item.name}</p>
                </div>
              ))}
          </div>
        ))}
      </div>
    );
  };

  return (
    <section id="compétences" ref={skillsRef}>
      <header className="skills-header">
        <h1>Compétences</h1>
      </header>
      <div className="skills-container">
        <div className="top-container">
          {/* Description */}
          <div className="description">
            <p>
              J'ai de l'expérience dans le développement front-end et back-end pour les environnements de bureau et web.
              <br />
              <br />
              J'ai travaillé sur une variété de projets, acquérant des compétences diverses et précieuses englobant le développement front-end et back-end dont les bases de données. Je suis particulièrement passionné par la conception et le développement logiciel.
            </p>
          </div>
          {/* Skills table */}
          <div className="table-container">
            <Skills />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;
