import React from 'react';
import '../sass/components/footer.scss';
import { IoMail } from 'react-icons/io5';
import { HiDocumentText } from 'react-icons/hi';
/*import cvFile from '../assets/CV.pdf'; */

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <div className="top">
        <div>
          <HiDocumentText onClick={() => null} /*onClick={() => window.open(cvFile, '_blank')}*/ />
        </div>
        <div>
          <IoMail
            onClick={() =>
              window.open('mailto:workfetcheptv@gmail.com', '_blank')
            }
          />
        </div>
      </div>
      <div className="bottom">
        <p>Développé par DJERIDI Mohamed Chawki @ {currentYear}</p>
      </div>
    </footer>
  );
};

export default Footer;
